<!-- 账号密码 -->
<template>
    <div class="viewsPages">
        <div class="UserInfo-title">{{$t("userPage.text220")}}</div>
        <div class="views-Password">
            <Form
                :model="form"
                :rules="rules"
                class="Password-form"
                ref="PasswordForm"
                label-width="150px">
                <!-- 旧密码 -->
                <Form-item :label='$t("userPage.text221")' prop="oldPass">
                    <Input class="fund-item-input" show-password :placeholder='$t("userPage.text222")' v-model="form.oldPass" />
                    <span>{{$t("userPage.text223")}}</span>
                </Form-item>
                <!-- 新密码 -->
                <Form-item :label='$t("userPage.text224")' prop="newPass">
                    <Input class="fund-item-input" show-password :placeholder='$t("userPage.text225")' v-model="form.newPass" />
                    <span>{{$t("userPage.text223")}}</span>
                </Form-item>
                <!-- 确认密码 -->
                <Form-item :label='$t("userPage.text228")' prop="confirmPass">
                    <Input class="fund-item-input" show-password :placeholder='$t("userPage.text227")' v-model="form.confirmPass" />
                    <span>{{$t("userPage.text226")}}</span>
                </Form-item>
                <div class="FundPassBtn">
                    <Button class="Password-btn" @click="modify('PasswordForm')">{{$t("userPage.text213")}}</Button>
                </div>
            </Form>
        </div>
    </div>
</template>

<script>
import { Input, Button, Form, FormItem,Message } from 'element-ui'
import { _jump } from '@/core/utils/utils'
import { _loginOut } from '../../../core/comp/common'
import { _modifyPassword } from '../../../core/comp/pages/userInfo'
export default {
    components: {
        Input,
        Button,
        Form,
        FormItem
    },
    data () {
        const confirmPass = (rule, value, callback) =>{
            if(!value) {
                return callback(new Error(this.$t("userPage.text230")))
            }else{
                if(value != this.form.newPass){
                    return callback(new Error(this.$t("userPage.text231")))
                }
            }
            callback();
        }
        return {
            form: {
                oldPass: '',
                newPass: '',
                confirmPass: '',
            },
            rules:{
                oldPass:[{required:true,message:this.$t("userPage.text232"),trigger: "blur"}],
                newPass:[{required:true,message:this.$t("userPage.text233"),trigger: "blur"}],
                confirmPass:[{required:true,validator:confirmPass,trigger: "blur"}],
            }
        }
    },
    methods: {
        _jump,
        // 修改资金密码
        modify (formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    _modifyPassword(
                        this.form,
                        () => { // 修改成功
                        this.form = {
                            oldPass: '',
                            newPass: '',
                            confirmPass: '',
                        }
                        this.loginOut()
                    })
                }
            })
        },
        loginOut () {
            _loginOut()
            setTimeout( () => {
                Message.warning(this.$t("userPage.text234"))
                this.$router.push({ name: 'home' })
            },500)
            
        },
    }
}
</script>

<style lang="scss">
$imgUrl: '~@/assets/images/my/';
.views-Password{
    width: 890px;
    margin: 20px auto;
    padding: 20px 40px;
    border-radius: 10px;
    background:#DEDEDE;
    .Password-form{
        margin-top: 40px;
        .el-form-item {
            .el-form-item__label {
                color: #8E8E8E;
            }
            .el-form-item__content{
                display:flex;
                align-items: center;
            }
            .el-input {
                width: 260px;
                margin-right: 20px;
                input{
                    width: 260px;
                    height: 50px;
                    line-height:50px;
                    padding: 0 15px;
                    border-radius: 6px;
                    color: #8E8E8E;
                    background:#fff;
                    border: 1px solid #ccc;
                    &::-webkit-input-placeholder{
                        color:#888888;
                    }
                }
            }
        }
        .FundPassBtn{
            margin-top: 80px;
            text-align: center;
            .Password-btn{
                color: #fff;
                border:none;
                margin: 0;
                height: 40px;
                text-align: center;
                font-size: 18px;
                background: #ce06cd;
                width: 45%;
                border-radius: 10px;
                // background:url(#{$imgUrl}tab_bg_active.png)no-repeat center/contain;
            }
        }
    }
}
</style>